import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/app/docs/node_modules/@uchiru/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The FilteredSearch component helps style a Dropdown and a TextInput side-by-side.`}</p>
    <p><strong parentName="p">{`Note:`}</strong>{` You `}<em parentName="p">{`must`}</em>{` use a `}<inlineCode parentName="p">{`TextInput`}</inlineCode>{` and `}<inlineCode parentName="p">{`Dropdown`}</inlineCode>{` (or native `}<inlineCode parentName="p">{`<details>`}</inlineCode>{` and `}<inlineCode parentName="p">{`<summary>`}</inlineCode>{`) in order for this component to work properly.`}</p>
    <h2>{`Default example`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`<FilteredSearch>
  <Dropdown>
    <Dropdown.Button>Filter</Dropdown.Button>
    <Dropdown.Menu direction="sw">
      <Dropdown.Item>Item 1</Dropdown.Item>
      <Dropdown.Item>Item 2</Dropdown.Item>
      <Dropdown.Item>Item 3</Dropdown.Item>
    </Dropdown.Menu>
  </Dropdown>
  <TextInput icon={Search} />
</FilteredSearch>
`}</code></pre>
    <h2>{`System props`}</h2>
    <p>{`FilteredSearch gets `}<inlineCode parentName="p">{`COMMON`}</inlineCode>{` system props. Read our `}<a parentName="p" {...{
        "href": "/about/system-props"
      }}>{`System Props`}</a>{` doc page for a full list of available props.`}</p>
    <h2>{`Component props`}</h2>
    <h4>{`FilteredSearch.Children`}</h4>
    <p>{`FilteredSearch is expected to contain a `}<a parentName="p" {...{
        "href": "/atoms/Dropdown"
      }}><inlineCode parentName="a">{`Dropdown`}</inlineCode></a>{` followed by a `}<a parentName="p" {...{
        "href": "/TextInput"
      }}><inlineCode parentName="a">{`TextInput`}</inlineCode></a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      